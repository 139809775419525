.services{

    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
   
}
.card{
    width:250px;
   flex-flow: flex wrap;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    padding: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-left: 20px;
    text-align: center;
    align-content: center;
}
.cc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}