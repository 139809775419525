.experience{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    text-align: left;
}
.excard{
    width:430px;
    height:430px;
    background-color: #FFF;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.excontent{
    width:380px;
    margin-left: 40px;
    border-left: 2px solid red;
    padding-left:20px ;
    margin-bottom:-15px;
    height: 110px;
}
.hatdate{
    display: flex;
    align-items: center;
    margin-bottom: -20px;
}

