.recent{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
ul{
    list-style: none;
    display: flex;
}

li{
    margin-right: 30px;
    cursor: pointer;
}
.recentcontainer{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  
}
.recentitem{
    width:420px;
    height:250px;
    margin: 20px;
    position: relative;
    display: flex;
    border-radius: 20px;
}
.recentitem:hover{
    cursor: pointer;
}
.recentitem:hover .recentproject{

    display:none;
    opacity: 0.2;
}
.recentitem:hover .toi{
    visibility: visible;
}

.recentproject{
    width:100%;
    border-radius: 20px;
}

.toi{
    width: 100%;
    position: absolute;
    visibility: hidden;
    color:#fd892d; 
}